
picker.module = null;

function picker(config) {

    var proto = {};

    proto.internal = {
        searchResults: null,
        searchResultsConf: null,
        element: null,
        bNoSearch: true,
        btnCreate: null,
        btnCSV: null,
        btnDelete:null,
        toggleCreate: function (disable) {
            if (disable === false) {
                proto.internal.btnCreate.attr('disabled', 'disabled');
            }
            else {
                proto.internal.btnCreate.removeAttr('disabled');
            }
        },
        toggleCSV: function (disable) {
            if (proto.internal.btnCSV) {
                if (disable === false) {
                    proto.internal.btnCSV.attr('disabled', 'disabled');
                }
                else {
                    proto.internal.btnCSV.removeAttr('disabled');
                }
            }
        },
        toggleDelete: function (disable) {
            if (proto.internal.btnDelete) {
                if (disable === false) {
                    proto.internal.btnDelete.attr('disabled', 'disabled');
                }
                else {
                    proto.internal.btnDelete.removeAttr('disabled');
                }
            }
        },
        singleConf: {},
        multiConf: {},
        table: null,
        oTableTools: null,
        loader: null,
        oExtraParams: {},
        loading: function () {
            return $('<div></div>').dialog({
                autoOpen: false,
                closeOnEscape: false,
                width: 100,
                draggable: false,
                resizable: false,
                modal: true,
                zIndex: 3000,
                open: function () {
                    $(this).spin({ length: 0, width: 15, radius: 40 });
                },
                create: function () {
                    var widget = $(this).dialog('widget');
                    //remove default title bar
                    $('.ui-dialog-titlebar', widget).remove();


                }
            });
        },
        handleError: function (xhr, status, err, callback,title) {
            
            var Errors = $.isArray(err) ? err : [err];
            

            try {
                r = JSON.parse(xhr.responseText); //this could through an exception
                if (r.Errors) {
                    Errors = r.Errors;
                }
            }
            catch (e) {
                if (console)
                    console.log(e.message);
            }
            finally {
                callback(Errors, xhr, xhr.status);

                var html = '';

                $.each(Errors, function (index, item) {
                    html = html + '<li>' + item + '</li>';
                });

                jAlert('<ul >' + html + '</ul>', title);
               

            }
        },
        fillParams: {},
        prefill: function (data) {
            if (data) {
               proto.internal.fillParams = data;
            }

            if (proto.internal.element && proto.internal.element instanceof jQuery) {
                $.each(proto.internal.fillParams, function (key, value) {
                    $('input[name="' + key + '"],select[name="' + key + '"],textarea[name="' + key + '"]', proto.internal.element).val(value);
                });
                proto.ext.fnFill({fill:proto.internal.fillParams}); //custom fills
                proto.internal.fillParams = {};
            }
        }
    };


    proto.base = {
        fnCreate: function (args) { },
        fnFill:function(args){},
        reset: function () { },
        oTarget: $('body:first'),
        bIsInline: false,
        bCreate: false,
        bDelete:false,
        options:{},
        bCreateOnEmpty:false,
        sLoadUrl: '',
        sTitle: '',
        selectId: function (aData) { },
        selectName: function (aData) { },
        getSearchParams: function (element) { return {}; },
        sSearchUrl: null,
        sDeleteUrl:null,
        sCSVUrl: null,
        onRowDraw: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) { },
        onReset: function () { },
        onCancel: function () { },
        onSelect: function (aSelection) { },
        onLoad: function () { },
        onPreLoad: function () { },
        onLoadError: function () { },
        onDeleteError: function () { },
        fnInit: function () { }, //caller should not override
        width: 1040,
        iPageLength: 5,
        sScrollY: '200px',
        sScrollX: null,
        sScrollXInner: null,
        aaSorting: [[1, 'asc']],
        bMultiSelect: false,
        bAutoHeight: false,
        bFilter: true,
        bPreFetch: false,
        actionColumnWidth: '1%',
        fnOnClear: function () { },
        params: {}

    };


    proto.base.onSearchError = function () { 
    
    }

    
    proto.base.fnDelete = function(item){
        var items = {};

        if (!item) {
            var selection = proto.internal.oTableTools.fnGetSelectedData();
            if (selection.length == 0) {
                jAlert('Please select rows to delete', 'Delete Error');
                return;
            }


            $.each(selection, function (index, item) {
                items["items[" + index + "]"] = proto.ext.selectId(item);
            });

        }
        else {
            items["items[0]"] = proto.ext.selectId(item);
        }

        jConfirm("Are you sure you want to delete the selected item(s)?", "Delete Confirmation",
        function (ok) {
            if (ok) {
                
                proto.internal.feedbackStart();
                var data = $.toRequestArray(proto.internal.mergeParams(), items);
                $.ajax({ url: proto.ext.sDeleteUrl, type: "POST", data: data })
                    .fail(function (xhr, status, err) {
                        proto.internal.handleError(xhr, status, err, proto.ext.onDeleteError, 'Error deleting selected item(s)');
                    })
                    .done(function (data) {
                        //debugger;
                        if (data.Errors) {
                            proto.internal.handleError(this.xhr(), null, data.Errors, proto.ext.onDeleteError, 'Error deleting selected item(s)');
                        }

                        proto.ext.search();
                    })
                    .always(function () {
                        proto.internal.feedbackEnd();
                    });
            }
        });



 

    }

    proto.base.processing = function (e, oSettings, bShow) {
        if (bShow)
            proto.internal.element.spin({ length: 0, width: 12, lines: 12, speed: 1.1, radius: 30 });
        else
            proto.internal.element.spin(false);

    }

    proto.base.select = function (aData) {
        var aSelection = [];

        if (!aData) { //used for multi-select mode

            aData = proto.internal.oTableTools.fnGetSelectedData(); //returns array of object
            aSelection = aSelection.concat(aData);
        }
        else if ($.isArray(aData)) { //argument was array of object
            aSelection = aSelection.concat(aData);
        }
        else { //argument was single object
            aSelection.push(aData);
        }

        if (aSelection.length == 0) {
            alert("No Items selected");
            return;
        }

        for (i in aSelection) {
            aSelection[i]._picker_id = proto.ext.selectId(aSelection[i]);
            aSelection[i]._picker_name = proto.ext.selectName(aSelection[i]);
        }

        proto.ext.onSelect(aSelection); //return array of object
        if (!proto.ext.bIsInline) {
            proto.ext.close(false);
        }

    }

    proto.base.unselect = function () {
        proto.internal.oTableTools.fnSelectNone();
    }

    proto.base.selectAll = function () {
        proto.internal.oTableTools.fnSelectAll();
    }

    proto.base.close = function (bCancel) {
        proto.internal.element.dialog('close');
        proto.internal.oExtraParams = {};

        if (bCancel) {
            proto.ext.onCancel();
        }
    };

    proto.base.search = function () {
        proto.internal.searchResults.fnDraw();
    }

    proto.base.reset = function () {

        $('.picker-form', proto.internal.element).find('input:text, input:password, input:file, input:hidden , textarea').val('');

        $('.picker-form', proto.internal.element).find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');

        $('.picker-form select', proto.internal.element).each(function () {
            $(this).val($('option:first', this).val());
        });

        // Clear any checked multi-select dropdown items
        $('.picker-form .ui-multiselect', proto.internal.element).prev("select").multiselect("uncheckAll");

        //force empty results
        proto.internal.bNoSearch = true;
        //redraw
        proto.internal.searchResults.fnDraw();
        //Adjust column sizing
        proto.internal.searchResults.fnAdjustColumnSizing(false);
        //Clear selections
        proto.ext.unselect();
        //Call user reset callback
        proto.ext.onReset();
        //Call user clear search callback
        proto.ext.fnOnClear();
    }

    proto.base.actionContent = function () {
        var content = '';
        if (!proto.ext.bIsInline) {
            content+= '<button class="btn btn-sm btn-primary picker-select" title="Select this item">Select</button>';
        }
        else {
            content+= '<button type="button" class="btn btn-sm btn-primary picker-select"  title="Select this item"><i class="fa fa-pencil" ></i></button>'
        }

        if (proto.ext.bDelete) {
            content += ' <button class="btn btn-sm btn-primary picker-delete" title="Delete this item"><i class="fa fa-times" ></i></button>';
        }

        return content;

    };

    proto.base.addActions = function () {
        var actionColumn = [{
            mDataProp: null,
            bSortable: false,
            sDefaultContent: proto.ext.actionContent(),
            sWidth: proto.ext.actionColumnWidth
        }];

        proto.ext.columns = actionColumn.concat(proto.ext.columns);
    }

    proto.base.singleConf = {
        sRowSelect: 'none',
        aButtons: [
                 {
                     sExtends: "text",
                     sButtonText: "Search",
                     sButtonClass: "btn btn-primary btn-xs",
                     fnInit: function (nButton, oConfig) {
                         $(nButton).prepend('<i class="fa fa-search fa-white"></i> ');
                     },
                     fnClick: function (nButton, oConfig, oFlash) {
                         proto.ext.search();
                     }
                 },

                   {
                       sExtends: "text",
                       sButtonText: "Clear",
                       sButtonClass: "btn btn-primary btn-xs",
                       fnInit: function (nButton, oConfig) {
                           $(nButton).prepend('<i class="fa fa-refresh fa-white"></i> ');
                       },
                       fnClick: function (nButton, oConfig, oFlash) {
                           proto.ext.reset();
                       }
                   }
        ]
    }

    proto.base.multiConf = {
        sRowSelect: 'multi',
        aButtons: [
                    {
                        sExtends: "text",
                        sButtonText: "Search",
                        sButtonClass: "btn btn-primary btn-xs",
                        fnInit: function (nButton, oConfig) {
                            $(nButton).prepend('<i class="fa fa-search fa-white"></i> ');
                        },
                        fnClick: function (nButton, oConfig, oFlash) {
                            proto.ext.search();
                        }
                    },
                    {
                        sExtends: "text",
                        sButtonText: "Choose",
                        sButtonClass: "btn btn-primary btn-xs",
                        fnClick: function (nButton, oConfig, oFlash) {
                            proto.ext.select();
                        }
                    },
                    {
                        sExtends: "text",
                        sButtonText: "Select All",
                        sButtonClass: "btn btn-primary btn-xs",
                        fnClick: function (nButton, oConfig, oFlash) {
                            proto.ext.selectAll();
                        }

                    },

                    {
                        sExtends: "text",
                        sButtonText: "Deselect All",
                        sButtonClass: "btn btn-primary btn-xs",
                        fnClick: function (nButton, oConfig, oFlash) {
                            proto.ext.unselect();
                        }

                    },

                   {
                       sExtends: "text",
                       sButtonText: "Clear",
                       sButtonClass: "btn btn-primary btn-xs",
                       fnInit: function (nButton, oConfig) {
                           $(nButton).prepend('<i class="fa fa-refresh fa-white"></i> ');
                       },
                       fnClick: function (nButton, oConfig, oFlash) {
                           proto.ext.reset();

                       }
                   }
        ]
    }

    proto.base.template = function () { //this is a function cause proto could patch the template if necessary. Just a thought
        var pickerHtml = "";
        var overflow = "";

        if (!proto.ext.bIsInline) {
            overflow = 'overflow:hidden;';
        }

        pickerHtml = '<div class="picker-container" style="display:none;' + overflow + '" >' +
           '<div class="row picker-header">' +
                '<div class="col-md-6  ">' +
                    '<h4 class="picker-title"></h4>' +
                '</div>' +
                '<div class="col-md-6 ">' +
                    '<i class="fa fa-times pull-right picker-close"></i>' +
                '</div>' +
            '</div>' +
            '<div class="picker-form"></div>' +
            '<table cellpadding="0" cellspacing="0" border="0" class="picker-table table table-striped table-bordered table-condensed"></table>'
        '</div>';

        return pickerHtml;
    };

    proto.internal.mergeParams = function () {
        return $.extend({}, proto.ext.params, proto.internal.oExtraParams);
    };
    //setup modal structure on demand
    proto.internal.create = function () {

        if (!proto.internal.element) {

            proto.internal.element = $(proto.ext.template());

            proto.ext.oTarget.append(proto.internal.element);
        }
        else {
            proto.internal.element.replaceWith(proto.ext.template());
        }

        proto.internal.table = $('.picker-table', proto.internal.element);

        proto.internal.element.css('padding-bottom', '10px');

        if (!proto.ext.bIsInline) {

            //create a jquery-ui dialog with the form and table container
            proto.internal.element.dialog({
                autoOpen: false,
                closeOnEscape: false,
                width: proto.ext.width,
                draggable:false,
                modal: true,
                zIndex: 2000,
                open: function () {
                    proto.internal.searchResults.fnAdjustColumnSizing(false);

                    $('.ui-widget-overlay').addClass('modal-overlay-dark');

                },
                beforeClose: function () {
                    $('.ui-widget-overlay').removeClass('modal-overlay-dark');
                    proto.ext.reset();
                },
                create: function () {
                    var widget = $(this).dialog('widget');
                    //remove default title bar
                    $('.ui-dialog-titlebar', widget).remove();

                 
                    //Bind custom close element in custom title
                    $('.picker-close', proto.internal.element).click(function (e) {
                        proto.ext.close(true);
                    });

                    $('.picker-header', proto.internal.element).css('cursor', 'pointer');

                    widget.draggable({
                        handle: '.picker-header'
                    });


                },
                resize: function () {
                    $(this).dialog('option', 'position', { my: "center" });

                }
            });
        }
        else {
            $('.picker-close', proto.internal.element).hide();
            proto.internal.element.show();

        }

        //bind enter event on form
        proto.internal.element.keypress(function (e) {
            if (e.keyCode == $.ui.keyCode.ENTER) {
                proto.ext.search();
            }
        });

        if (proto.ext.bMultiSelect) {
            proto.internal.table.css('cursor', 'pointer');
        }


    }

    //Adds retrieved form data to modal window
    proto.internal.fnLoadForm = function (html, showOnLoad) {

        //setup structure
        proto.internal.create();

        //Add module html
        $('.picker-form', proto.internal.element).html(html);


        if (!picker.module) {
            throw new Error("Failed to load module at:" + proto.ext.sLoadUrl);
        };

        proto.ext = $.extend({}, proto.base, picker.module, config);

        picker.module = null;

        proto.internal.prefill();

        if (proto.ext.bCreate) {
            var createButton = {
                sExtends: "text",
                sButtonText: "Create New",
                sButtonClass: "btn btn-primary btn-xs",
                fnInit: function (nButton, oConfig) {
                    $(nButton).prepend('<i class="fa fa-plus fa-white"></i> ');
                    if (proto.ext.bCreateOnEmpty) {
                        proto.internal.btnCreate = $(nButton);
                    }

                },
                fnClick: function (nButton, oConfig, oFlash) {

                    proto.ext.fnCreate({
                        loadParams: proto.internal.mergeParams(), 
                        searchParams: proto.ext.getSearchParams(proto.internal.element),
                        element: proto.internal.element, 
                        fill: proto.internal.prefill,
                        reset: proto.ext.reset,
                        search: proto.ext.search,
                        inline: proto.ext.bIsInline,
                        options:proto.ext.options
                    });
                }
            }
            proto.ext.multiConf.aButtons.push(createButton);
            proto.ext.singleConf.aButtons.push(createButton);

        }

        //if (proto.ext.bDelete) {
        //    var deleteButton = {
        //        sExtends: "text",
        //        sButtonText: "Delete",
        //        sButtonClass: "btn-primary ",
        //        fnInit: function (nButton, oConfig) {
        //            $(nButton).prepend('<i class="icon-trash icon-white"></i> ');
        //            proto.internal.btnDelete = $(nButton);
        //            proto.internal.toggleDelete(false);
        //        },
        //        fnSelect: function (nButton, oConfig, nRow) {

        //            var selection = proto.internal.oTableTools.fnGetSelectedData();
        //            proto.internal.toggleDelete(selection.length > 0);
        //        },

        //        fnClick: function (nButton, oConfig, oFlash) {
        //            proto.ext.fnDelete();
        //        }
        //    }
        //    proto.ext.multiConf.aButtons.push(deleteButton);
        //    proto.ext.singleConf.aButtons.push(deleteButton);

        //}

        if (proto.ext.options.CSV) {
            var csvButton = {
                sExtends: "text",
                sButtonText: "Export To Excel",
                sButtonClass: "btn btn-primary btn-xs",
                fnInit: function (nButton, oConfig) {
                    $(nButton).prepend('<i class="fa fa-download-alt fa-white"></i> ');
                    proto.internal.btnCSV = $(nButton);
                },
                fnClick: function (nButton, oConfig, oFlash) {
                    var params = proto.internal.searchResults.oApi._fnAjaxParameters(proto.internal.searchResults.fnSettings());
                    var searchparams = $.toRequestArray(proto.internal.mergeParams(), proto.ext.getSearchParams(proto.internal.element));

                    var frame = $('<iframe></iframe>')
                        .appendTo(document.body)
                        .css('display', 'none');

                    var framebody = frame[0].contentDocument.body;
                    $(framebody).html('<form method="post" action="'+ proto.ext.sCSVUrl +'" ></form>');
                      
                    //Add Datatable params
                    $.each(params, function (index, item) {
                        var hidden = $('<input>')
                                        .attr('type', 'hidden')
                                        .attr('name', item.name)
                                        .attr('value', item.value);
                        $('form',framebody).append(hidden);
                    });

                    //add picker search and additional params
                    $.each(searchparams, function (index, item) {
                        var hidden = $('<input>')
                            .attr('type', 'hidden')
                            .attr('name', item.name)
                            .attr('value', item.value);
                        $('form', framebody).append(hidden);
                        
                    });

                    $('form', framebody).submit();

                }
            }
            proto.ext.multiConf.aButtons.push(csvButton);
            proto.ext.singleConf.aButtons.push(csvButton);

            
        }

        //add edit or select in first column
        proto.ext.addActions();

        proto.ext.fnInit(proto.internal.element, proto.ext.close, proto.ext.bIsInline);

        proto.internal.searchResults = proto.internal.table
                                                        .on('processing.dt', proto.ext.processing)
                                                        .dataTable(proto.ext.searchResultsConf());

        proto.internal.oTableTools = TableTools.fnGetInstance(proto.internal.table[0])

        //proto.internal.searchResults.bind('processing', proto.ext.processing);

        //we need to do some setup work based on overriden configs
        $('.picker-title', proto.internal.element).text(proto.ext.sTitle);

       // $('select', proto.internal.element).height('28px');


        if (!proto.ext.bIsInline) {
            //set mixed in width
            proto.internal.element.dialog('option', 'width', proto.ext.width);
        }

        if (showOnLoad) {
            proto.internal.element.dialog('open');
        }

        if (proto.ext.bPreFetch) {
            proto.ext.search();
        }

    };

    proto.internal.fnGetForm = function (showOnLoad) {

        proto.internal.feedbackStart();


        //sInstanceId is a random key that allows binding of the loaded module.
        var loadParams = $.toRequestArray(proto.internal.mergeParams());

        proto.ext.onPreLoad(loadParams);

        $.ajax({ url: proto.ext.sLoadUrl, type: "POST", dataType: "html", data: loadParams })
            .fail(function (xhr, status, err) {
                proto.internal.handleError(xhr, status, err, proto.ext.onLoadError, 'Error Loading Search Form');
            })
            .done(function (data) {
                proto.internal.fnLoadForm(data, showOnLoad);
                proto.ext.onLoad(loadParams);
            })
            .always(function () {
                proto.internal.feedbackEnd();
            });

    };

    proto.internal.feedbackStart = function () {
        if (proto.ext.bIsInline) {
            proto.ext.oTarget.spin({ length: 0, width: 12, lines: 12, speed: 1.1, radius: 30 });
        }
        else {
            if (proto.internal.loader == null) {
                proto.internal.loader = proto.internal.loading();
            }
            proto.internal.loader.dialog('open');
        }
    };

    proto.internal.feedbackEnd = function () {
        if (proto.ext.bIsInline)
            proto.ext.oTarget.spin(false);
        else
            proto.internal.loader.dialog('close');
    };
   
    //Datatable initialization config
    proto.base.searchResultsConf = function () {
        return {
            bProcessing: false,
            oLanguage: {  //Set the processing message to empty text, since we want just the img from the CSS
                sProcessing: '',
                sSearch: 'filter:'
            },
            bServerSide: true,
            bPaginate: true,
            bSort: true,
            bDeferRender: true,
            bFilter: proto.ext.bFilter,
            bInfo: true,
            bDestroy: true,
            bAutoWidth: false,
            bScrollCollapse: true,
            aaSorting: proto.ext.aaSorting,
            sScrollY: proto.ext.bAutoHeight ? '' : proto.ext.sScrollY,
            sScrollX: proto.ext.bAutoHeight ? '' : proto.ext.sScrollX ? proto.ext.sScrollX : '100%',
            sScrollXInner: proto.ext.bAutoHeight ? '' : proto.ext.sScrollXInner ? proto.ext.sScrollXInner : '100%',
            iDisplayLength: proto.ext.iPageLength,
            sAjaxSource: proto.ext.sSearchUrl,
            fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $(nRow).dblclick(function () {
                    proto.ext.select(aData);
                });

                $('.picker-select', nRow).click(function () {
                    proto.ext.select(aData);
                });

                $('.picker-delete', nRow).click(function () {
                    proto.ext.fnDelete(aData);
                });

                //JF: changed so that "this" applies to the datatable object
                //proto.ext.onRowDraw(nRow, aData, iDisplayIndex, iDisplayIndexFull);
                proto.ext.onRowDraw.apply(this, [nRow, aData, iDisplayIndex, iDisplayIndexFull, proto.ext.bIsInline]);
            },
            fnServerParams: function (aoData) {

                var searchparams = $.toRequestArray(proto.internal.mergeParams(), proto.ext.getSearchParams(proto.internal.element));

                $.each(searchparams, function (index, item) {
                    aoData.push({ name: item.name, value: item.value });
                });
            },
            fnDrawCallback: function (oSettings) {
                if (!proto.ext.bIsInline) {
                    proto.internal.element.dialog('option', 'position', { my: "center" });

                }

                if (oSettings._iRecordsTotal == 0 && proto.ext.bCreate && proto.ext.bCreateOnEmpty) {
                    proto.internal.toggleCreate(); //enable create
                } else if (oSettings._iRecordsTotal != 0 && proto.ext.bCreate && proto.ext.bCreateOnEmpty) {
                    proto.internal.toggleCreate(false); //disable create
                }

   
                proto.internal.toggleCSV(oSettings._iRecordsTotal != 0);
                var timeout;
                $(window).resize(function () {

                    clearTimeout(timeout);
                    timeout = setTimeout(function () {
                        oSettings.oApi._fnScrollDraw(oSettings);
                      
                    }, 200);
                });
                
            },
            fnServerData: function (sSource, aoData, fnCallback) {

                if (!proto.internal.bNoSearch) {
                    $.ajax({
                        dataType: 'json',
                        type: "POST",
                        url: sSource,
                        data: aoData,
                        success: fnCallback,
                        error: function (xhr, status, err) {
                            proto.internal.searchResults.fnProcessingIndicator(false);
                            proto.internal.handleError(xhr, status, err, proto.ext.onSearchError, 'Error Executing Search');
                        }

                    });
                }
                else {
                    fnCallback({ sEcho: aoData[0].value, iTotalRecords: 0, iTotalDisplayRecords: 0, aaData: [] });
                    
                    if (proto.ext.bCreate && proto.ext.bCreateOnEmpty) {
                        proto.internal.toggleCreate(false);
                    }

                    proto.internal.bNoSearch = false;
                }

            },
            aoColumns: proto.ext.columns,
            oLanguage: {
                sProcessing: '',
                sSearch: 'Filter: ',
                sInfo: "_TOTAL_ results",
                sInfoEmpty: "0 results",
                sInfoFiltered: "(filtered from _MAX_ total results)"
            },
            sDom: "<'row'<'col-md-6'T><'col-md-6'f>>t<'row'<'col-md-6'i><'col-md-6'" + (proto.ext.bAutoHeight ? 'p' : 'S') + ">>",
            oTableTools: proto.ext.bMultiSelect ? proto.ext.multiConf : proto.ext.singleConf
        };
    };

    proto.ext = $.extend({}, proto.base, config);


    if (proto.ext.bIsInline) {
        proto.internal.fnGetForm(false);
    }



    //Return anonymous object with public methods
    return fluent = {

        pick: function (params) { //added after the fact

            proto.internal.oExtraParams = params || {};


            if (!proto.ext.bIsInline && proto.internal.element != null && !proto.ext.bNoCache) {
                proto.internal.element.dialog('open');
                if (proto.ext.bPreFetch) {
                    proto.ext.search();
                }


            }
            else {
                proto.internal.fnGetForm(!proto.bIsInline);
            }

            return fluent;
        },
        search: function () {
            proto.ext.search();
            return fluent;
        },
        reset: function () {
            proto.ext.reset();
            return fluent;
        },
        fill: function (data) {
            proto.internal.prefill(data);
            return fluent;
        }

    }
}