; (function ($) {
    $.extend({ //need to make this a simple recurrsion function
        toRequestArray: function () {
            var aData = [];

            for (var index in arguments) {
                $.each(arguments[index], function (key, value) {
                    if ($.isPlainObject(value)) { // {prop1:'prop1',prop2:'prop2'}
                        $.each(value, function (childKey, childValue) {
                            aData.push({ name: key + "." + childKey, value: childValue });
                        });
                    }
                    else if ($.isArray(value)) { //[{prop1:'prop1',prop2:'prop2'},{prop1:'prop1',prop2:'prop2'}]
                        $.each(value, function (index, childValue) {
                            if ($.isPlainObject(childValue)) { //[{prop1:{ prop3: 'prop3'},prop2:{prop4:'prop4'},{prop1:'prop1',prop2:'prop2'}]
                                $.each(childValue, function (grandChildKey, grandChildValue) {
                                    if ($.isArray(grandChildValue)) { //[{prop1:'prop1',prop2:'prop2'},{prop1:'prop1',prop2:[1,2]}]
                                        $.each(grandChildValue, function (greatGrandChildIndex, greatGrandChildValue) {
                                            aData.push({ name: key + "[" + index + "]." + grandChildKey + "[" + greatGrandChildIndex + "]", value: greatGrandChildValue });
                                        });
                                    }
                                    else {
                                        aData.push({ name: key + "[" + index + "]." + grandChildKey, value: grandChildValue });
                                    }
                                });
                            }
                            else {
                                aData.push({ name: key + "[" + index + "]", value: childValue });
                            }

                        });
                    }
                    else { //prop = "prop"
                        aData.push({ name: key, value: value });
                    }
                });
            }
            return aData;
        }
    })
}(jQuery));