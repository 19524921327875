
viewer.module = null;
viewer.subModules = [];

function viewer(config) {

    var ext = {},
        modules =[],
        mergeParams = function () {
            return $.toRequestArray( $.extend({}, ext.params,oExtraParams) );
        },
        element= null,
        el = function (selector) { return element.find(selector) },
        busy =function (bShow) {
            if (bShow !== false) {
                if (!ext.bIsInline && !element.is(':visible')) {
                    element.dialog('open');
                }
                element.spin({ length: 0, width: 12, lines: 12, speed: 1.1, radius: 30 });
            }
            else {
                element.spin(false);
            }

        },
        oExtraParams= {},
        handleError= function (xhr, status, err, callback,title) {
            var Errors = [err];

            try {
                r = JSON.parse(xhr.responseText); //this could through an exception
                if (r.Errors) {
                    Errors = r.Errors;
                }
            }
            catch (e) {
                if (console)
                    console.log(e.message);
            }
            finally {
                callback(Errors, xhr, xhr.status);

                var html = '';

                $.each(Errors, function (index, item) {
                    html = html + '<li>' + item + '</li>';
                });

                jAlert('<ul >' + html + '</ul>', title);
                if (!ext.bIsInline) {
                    element.dialog('close');
                }

            }
        },
        template = function () {
            return '<div class="viewer-container" style="display:none" ><div class="viewer-container-inner>"' +
                           '<div class="row viewer-header">' +
                                '<div class="col-md-6  ">' +
                                    '<h4 class="viewer-title"></h4>' +
                                '</div>' +
                                '<div class="col-md-6 ">' +
                                    '<i class="fa fa-times pull-right viewer-close"></i>' +
                                '</div>' +
                            '</div>' +
                            '<div class="viewer-content"></div>' +
                        '</div>' +
                    '</div>'
        },
        create = function () {

        if (element) {
            element.remove();
        }
     
        element = $(template());
        ext.oTarget.append(element);
       
        if (!ext.bIsInline) {

            //create a jquery-ui dialog with the form and table container
            element.dialog({
                autoOpen: false,
                closeOnEscape: false,
                width: ext.width,
                modal: true,
                zIndex: 2000,
                draggable:false,
                resizeStop: function (event, ui) { center(); },
                create: function () {
                    var widget = $(this).dialog('widget');
                    //remove default title bar
                    $('.ui-dialog-titlebar', widget).remove();

                    //Bind custom close element in custom title
                    el('.viewer-close').click(function (e) {
                        close();
                    });
                    
                    el('.viewer-header').css('cursor', 'pointer');

                    widget.draggable({
                        handle: '.viewer-header'
                    });
                  
                }
            });
        }
        else {
            el('.viewer-close').hide();
            element.show();

        }
        },
        fnLoadViewer = function (html) {
           
            //Add module html
                el('.viewer-content').html(html);
                

                if (!viewer.module) {
                    throw new Error("Failed to load module at:" + ext.sLoadUrl);
                };

                ext = $.extend({}, base, viewer.module, config);
                modules = viewer.subModules;

                viewer.module = null;
                viewer.subModules = [];

                //we need to do some setup work based on overriden configs
                el('.viewer-title').html(ext.sTitle);

                if (!ext.bIsInline) {
                    //set mixed in width
                    element.dialog('option', 'width', ext.width);
                   
                }
                ext.fnInit({ element: element, center: center, busy: busy, on: _on, trigger: _trigger, close: close, off: _off });
                
                for (m in modules) {
                    modules[m].fnInit({ element: element, ext: ext, center: center, on: _on, trigger: _trigger, close: close });
                }

            },
        fnGetViewer = function () {
            create();
            busy();

            var loadParams = mergeParams();

            ext.onPreLoad(loadParams);

            $.ajax({ url: ext.sLoadUrl, type: "POST", dataType: "html", data: loadParams })
                .fail(function (xhr, status, err) {
                    handleError(xhr, status, err, ext.onLoadError, 'Error Loading view at: ' + ext.sLoadUrl);
                })
                .done(function (data) {
                    fnLoadViewer(data);
                    ext.onLoad(loadParams);
                })
                .always(function () {
                    busy(false);
                });

        },
        center = function () {
            if (!ext.bIsInline) {
                element.dialog('option', 'position', { my: "center" });
                var size = ext.fnResize({ width: element.dialog('option', 'width'), height: element.dialog('option', 'height') });

                if (size.width) {
                    element.dialog('option', 'width', size.width);
                }

                if (size.height) {
                    element.dialog('option', 'height', size.height);
                }
            }

        },
        close = function (result) {
            _trigger('Close');
            element.dialog('close');
            ext.onClose(result);
        },
        _events = {},
        _trigger = function(eventName,args){
            $(_events).trigger(eventName, args);
        },
        _on = function (eventName, fn) {
            $(_events).on(eventName, fn);
        },
        _off = function (events,sel ,fn) {
            $(_events).off(events, sel, fn);
        };
      


    var base = {
        refresh: function () { },
        oTarget: $('body:first'),
        bIsInline: false,
        sLoadUrl: '',
        sTitle: '',
        fnInit: function (o) { }, //caller should not override
        width: 600,
        params: {},
        onLoad: function () { },
        onLoadError: function (err, xhr, status) { },
        onClose: function () { },
        fnResize: function () { return {} },
        fnCanClose:function(){ return true;},
        onPreLoad: function (loadParams) { }
    };


    ext = $.extend({}, base, config);

    if (ext.bIsInline) {
        fnGetViewer();
    }



    //Return anonymous object with public methods
    return fluent = {

        load: function (o) { //added after the fact
          
            oExtraParams =  o && o.params? o.params : {};

            if (o && o.target) {
                oTarget = $(o.target);
            }

            fnGetViewer();

            return fluent;
        },
        show:function(){
            if (!ext.bIsInline) {
                element.dialog('open');
            }

            return fluent;
        },
        hide:function(){
            if (!ext.bIsInline) {
                close();
            }

            return fluent;
        },
        refresh: function () {
            fnGetViewer();

            return fluent;
        },
        trigger: function (eventName, args) {
            _trigger(eventName, args);

            return fluent;
        }


    }
}