(function SystemItemControl() {

    function wrap($context, before, fn) {
        return function () {
            before.apply($context, arguments);
            return fn.apply($context, arguments);
        };
    };


    function InitControl(placeholderElement, config) {
        var ctl = new $.fn.systemitem.controls[config.type](config);
        ctl.init($(placeholderElement));
        return ctl;
    };

    //plain text control
    function TextControl(config) {
        var self = this;

        self.config = config;

        Object.defineProperty(self, 'element', {
            value: $('<input type="text">'),
            writable: false,
            enumerable: true,
            configurable: true
        });

        Object.defineProperty(self, "value", {
            get: function () { return self.element.val(); },
            set: function (val) { self.element.val(val); },
            configurable: true
        });

        self.init = function ($placeholder) {

            $placeholder.css('display', 'none');//hide placeholder

            if (self.config.class) {
                self.element.addClass(self.config.class);
            }

            //this will probably be the most common case
            if (self.config.value) {
                self.element.val(self.config.value);
            }

            if (self.config.attributes) {
                self.element.attr(self.config.attributes);
            }

            self.element.insertAfter($placeholder);

        };

    }

    function BigTextControl(config) {
        var self = this;
        TextControl.call(self, config);

        Object.defineProperty(self, 'element', {
            value: $('<textarea>'),
            writable: false,
            enumerable: true,
            configurable: true
        });

    }

    function RichTextControl(config) {
        var self = this;
        BigTextControl.call(self, config);

        self.init = wrap(self, self.init, function ($placeholder) {
            self.getSet = self.config.RichTextInit(self.element);

        });

        Object.defineProperty(self, "value", {
            get: function () { return self.getSet ? self.getSet.value : self.element.val(); },
            set: function (val) { self.getSet ? self.getSet.value = val : self.element.val(); }
        });

    }


    function ComboControl(config) {
        var self = this;
        TextControl.call(self, config);

        Object.defineProperty(self, 'element', {
            value: $('<select>'),
            writable: false,
            enumerable: true,
            configurable: true
        });


        self.init = wrap(self, self.init, function () {
            $.each(self.config.items, function (index, item) {
                $('<option value="' + item.Key + '" ' + (item.Key == self.config.value || item.Selected ? 'selected' : '') + ' >' + item.Label + '</option>').appendTo(self.element);
            })
        });
    }

    function MultiComboControl(config) {
        var self = this;
        ComboControl.call(self, config);
        
        self.init = wrap(self, self.init, function () {
            self.getSet = self.config.MultiComboInit(self.element);
        });

        self.init = wrap(self, function () {
            self.element.attr('multiple', 'multiple');
        }, self.init);

        Object.defineProperty(self, "value", {
            get: function () { return self.getSet.value },
            set: function (val) { self.getSet.value = val }
        });
    }

    function CheckboxControl(config) {
        var self = this;
        TextControl.call(self, config);

        Object.defineProperty(self, 'element', {
            value: $('<input type="checkbox">'),
            writable: false,
            enumerable: true,
            configurable: true
        });

        self.init = wrap(self, self.init, function () {
            self.element.prop("checked", self.config.value == 'true' || self.config.value == 'True' || self.config.value === true);
        });

        Object.defineProperty(self, "value", {
            get: function () { return self.element.is(':checked') ? 'true' : 'false'; },
            set: function (val) { self.element.prop('checked', val == 'true' || val == 'True' || val === true); }
        });

    }



    $.fn.systemitem = function (option, args) {
        return this.each(function () {
            var $this = $(this)
              , data = $this.data('systemitem')
              , options = $.extend({}, $.fn.systemitem.defaults, $this.data(), typeof option == 'object' && option)
            if (!data) {
                $this.data('systemitem', InitControl(this, options))
            }


        })
    }

    //Extensible: Entry point for setting behavior for controls with external dependencies
    $.fn.systemitem.defaults = {
        RichTextInit: function (element) {
            element.tinymce({});
            return null; //use built in get/set
        },
        MultiComboInit: function (element) {
            element.multiselect({
                noneSelectedText: '',
                selectedList: 5
            });

            return {
                get value() {
                    return $(element).multiselect("getChecked").map(function () {
                        return this.value;
                    }).get().join(',');
                },
                set value(val) {
                    val = $.isArray(val)? val : val.join(',');
                    $('option', element).each(function (index, item) {
                        var opt = $(this);
                        var optval = opt.attr('value');
                        var selected = false;
                        for (i in val) {
                            if (optval == val[i]) {
                                opt.attr('selected', 'selected');
                                break;
                            }
                        }
                        if (!selected) {
                            opt.removeAttr('selected');
                        }
                    });
                    element.multiselect('refresh');
                }
            }
            return null; //use built in get/set
        }

    }

    //Extensible: Ability to add additional controls or replace existing ones
    $.fn.systemitem.controls = {
        'text': TextControl,
        'bigtext': BigTextControl,
        'richtext': RichTextControl,
        'combo': ComboControl,
        'multicombo': MultiComboControl,
        'check': CheckboxControl
    }


})();


