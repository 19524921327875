
var appa = {
    GetBoolSearchVal: function (selector) {
        return ((selector.val() == "-1" || selector.val() == "") ? null :
               (selector.val() === "1"));
    },

    GetInputVal: function (elName, element) {
        return $('input[name="' + elName + '"]', element).val();
    },

    GetTextAreaVal: function (elName, element) {
        return $('textarea[name="' + elName + '"]', element).val();
    },

    GetSelectVal: function (elName, element) {
        return $('select[name="' + elName + '"]', element).val();
    },

    GetCheckBoxVal: function (elName, element) {
        return $('input[id="' + elName + '"]', element).is(":checked");
    },

    GetCheckBoxValByName: function (elName, element) {
        return $('input[name="' + elName + '"]:checked', element).val();
    },

    Strip: function (html) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    },

    htmlEncode: function (html) {
        return document.createElement('a').appendChild(
            document.createTextNode(html)).parentNode.innerHTML;
    },

    MakeProgress: function(data) {
        var divdata = "";

        if (data) {
            var rounded = Math.round(data);

            divdata =
              '<div class="progress" style="min-width:100px;margin-bottom:0">' + 
              '  <div class="progress-bar-success" role="progressbar" aria-valuenow="' + rounded + '" aria-valuemin="0" aria-valuemax="100" style="width: ' + rounded + '%;">' +
              '    <span>' + rounded + '%</span>' +
              '  </div>' +
              '</div>';

        }
        return divdata;
    },

    MakeCheck: function(data) {
        return '<input type="checkbox"  ' + (data ? 'checked' : '') + ' disabled />';
    }
};